<div class="dashboard-container">
    <h1>Activate profile</h1>

    <div *ngIf="categories && charity else loader">
        <app-charity-crud-form
            buttonTitle="Go live"
            [charity]="charity"
            [categories]="categories"
            (onSubmit)='handleSubmit($event)'
        />
    </div>
    <ng-template #loader>
        <div class="relative min-h-[500px]">
            <app-widget-loader [iconSize]="88"/>
        </div>
    </ng-template>
</div>

<app-modal [open]="showActivateModal" (onOpenChanged)="showActivateModal = $event">
    <app-modal-content size="md">
        <app-modal-header>
            <app-modal-title>Hey, activate your profile first</app-modal-title>
        </app-modal-header>

        <p>
            Little Phil customises our platform around your unique profile.
            <br><br>
            Creating your profile only takes 5 mins and you’ll be live and ready to fundraise.
            Plus, we’ll drop you right back to where you were after you’re done.
        </p>

        <app-modal-footer>
            <button class="btn btn-secondary" (click)="showActivateModal = false">
                Continue
            </button>
        </app-modal-footer>
    </app-modal-content>
</app-modal>

<app-modal [open]="showContinueModal" (onOpenChanged)="showContinueModal = $event">
    <app-modal-content size="md">
        <app-modal-header>
            <app-modal-title>You’re almost ready to fundraise!</app-modal-title>
        </app-modal-header>

        <p>Your profile is live. Now time to verify a few organisation details to start accepting donations.</p>

        <p class="mt-6 mb-2">Your Little Phil profile link</p>
        <div class="flex justify-between items-center gap-2 border border-primary-50 p-3 rounded max-w-[400px]">
            <span class="min-w-0 truncate">{{profileUrl}}</span>
            <app-svg-icon
                class="cursor-pointer"
                [svg]="COPY_16_SVG"
                (click)="copyProfileLink()"
            />
        </div>

        <app-modal-footer>
            <button
                class="btn btn-secondary d-flex align-items-center gap-2"
                (click)="navigateToAccountVerification()">
                <span>Start verifying</span>
                <app-svg-icon [svg]="ARROW_RIGHT_SVG"/>
            </button>

            <button
                class="btn btn-outline-primary d-flex align-items-center gap-2"
                (click)="navigationToProfile()">
                <span>View profile</span>
                <app-svg-icon [svg]="EXTERNAL_16_ARROW"/>
            </button>
        </app-modal-footer>
    </app-modal-content>
</app-modal>
