import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LpNavigationModule } from '../../../_modules/lp-navigation/lp-navigation.module';
import { TopNavigatorComponent, TopNavigatorItem } from '../../../_modules/lp-navigation/components/top-navigator/top-navigator.component';
import {
    DashboardSettingsOrganisationComponent,
} from '../components/dashboard-settings-organisation/dashboard-settings-organisation.component';
import {
    DashboardSettingsPayoutsComponent,
} from '../components/dashboard-settings-payouts/dashboard-settings-payouts.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardSettingsBillingComponent } from '../components/dashboard-settings-billing/dashboard-settings-billing.component';
import {
    DashboardSettingsVerificationComponent,
} from '../components/dashboard-settings-verification/dashboard-settings-verification.component';

@Component({
    selector: 'app-dashboard-settings-charity',
    standalone: true,
    imports: [CommonModule, LpNavigationModule],
    templateUrl: './dashboard-settings-charity.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardSettingsCharityComponent implements OnInit {

    @ViewChild('navigator') private navigator: TopNavigatorComponent;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit() {
        this.setupTopNavigation();
    }

    private setupTopNavigation() {
        const organisationItem = new TopNavigatorItem(
            CHARITY_SETTINGS_TAB.ORGANISATION,
            DashboardSettingsOrganisationComponent
        );
        const bankingItem = new TopNavigatorItem(
            CHARITY_SETTINGS_TAB.BANKING,
            DashboardSettingsPayoutsComponent
        );
        // const billingItem =
        //     new TopNavigatorItem('Billing', DashboardSettingsBillingComponent);
        const verificationItem = new TopNavigatorItem(
            CHARITY_SETTINGS_TAB.VERIFICATION,
            DashboardSettingsVerificationComponent
        );

        const tabQueryParam: CHARITY_SETTINGS_TAB = this.route.snapshot.queryParams.tab;

        let selectedTab: TopNavigatorItem;
        switch (tabQueryParam) {
            case CHARITY_SETTINGS_TAB.BANKING:
                selectedTab = bankingItem;
                break;
            case CHARITY_SETTINGS_TAB.ORGANISATION:
                selectedTab = organisationItem;
                break;
            // case CHARITY_SETTINGS_TAB.BILLING:
            //     selectedTab = billingItem;
            //     break;
            case CHARITY_SETTINGS_TAB.VERIFICATION:
                selectedTab = verificationItem;
                break;
        }

        setTimeout(() => {
            this.navigator.setup([
                organisationItem,
                bankingItem,
                // billingItem,
                verificationItem,
            ], selectedTab);
        }, 0);
    }

    public updateTabQueryParam(item: TopNavigatorItem) {
        const queryParams = { tab: item.title };

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
            },
        );
    }

}

export enum CHARITY_SETTINGS_TAB {
    BANKING = 'Banking',
    // BILLING = 'Billing',
    ORGANISATION = 'Organisation',
    VERIFICATION = 'Verification',
}
