<div [formGroup]="form" *ngIf="form else loader">
    <div class="max-w-[500px] mt-7">
        <div class="grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-7">
            <app-dashboard-form-input
                [control]="form.controls.bsb"
                name="BSB"
                label="BSB"
                placeholder="000 000"
                [required]="true"
            />

            <app-dashboard-form-input
                [control]="form.controls.accountNumber"
                name="Account number"
                label="Account number"
                placeholder="0000 0000"
                [required]="true"
            />

            <app-dashboard-form-input
                [control]="form.controls.accountHolderName"
                class="md:col-span-2"
                name="Account holder name"
                label="Account holder name"
                placeholder="Little Phil Australia"
                details="Name of the person or business that owns the bank account."
                [required]="true"
            />
        </div>
    </div>

    <div class="mt-7">
        <button
            class="btn btn-secondary d-flex align-items-center gap-2"
            (click)="handleSubmit()">
            <span>Save</span>
            <app-svg-icon *ngIf="!isSubmitting" [svg]="ARROW_RIGHT_SVG"/>
            <i *ngIf="isSubmitting" class="icon-16 icon-loader-white-16 icon-spin"></i>
        </button>
    </div>
</div>

<ng-template #loader>
    <div class="relative h-[400px]">
        <app-widget-loader [iconSize]="44"/>
    </div>
</ng-template>
