import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardFormInputComponent } from '../../../../_components/forms/dashboard-form-input/dashboard-form-input.component';
import { SvgIconComponent } from '../../../../_components/svg-icon/svg-icon.component';
import { WidgetLoaderComponent } from '../../../../_components/widget-loader/widget-loader.component';
import { FormComponent } from '../../../../_classes/form-component.class';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../../../_services/logger.service';
import { AuthService } from '../../../../_services/auth.service';
import { DashboardContextService } from '../../../contexts/dashboard-context.service';
import { ToastrService } from 'ngx-toastr';
import { SentryService } from '../../../../_services/sentry.service';
import { extractErrorMessageFromError } from '../../../../_utils/errors.util';
import { ARROW_RIGHT_SVG } from 'src/app/_utils/icons-brand.util';
import { CharityCardComponent } from '../../../../_components/charity-card/charity-card.component';
import { DashboardFormControlComponent } from '../../../../_components/forms/dashboard-form-control/dashboard-form-control.component';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
    selector: 'app-dashboard-settings-payouts',
    standalone: true,
    imports: [
        CommonModule,
        CharityCardComponent,
        DashboardFormControlComponent,
        DashboardFormInputComponent,
        SvgIconComponent,
        ReactiveFormsModule,
        WidgetLoaderComponent,
    ],
    templateUrl: './dashboard-settings-payouts.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardSettingsPayoutsComponent extends FormComponent implements OnInit {

    private id: string;
    private payoutSettings: PayoutSettings;

    public isLoading = false;
    public isSubmitting = false;

    public form: FormGroup<{
        accountHolderName: FormControl<string>,
        accountNumber: FormControl<string>,
        bsb: FormControl<string>,
    }>;

    protected readonly ARROW_RIGHT_SVG = ARROW_RIGHT_SVG;

    constructor(
        protected logger: LoggerService,
        private authService: AuthService,
        private fb: FormBuilder,
        private toast: ToastrService,
        private sentry: SentryService,
        private http: HttpClient,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        super(logger);
    }

    ngOnInit() {
        const user = this.authService.getUser();

        this.id = user.managedCharity;
        if (!this.id) {
            return;
        }

        this.loadPayoutSettings()
            .then(() => this.createForm());
    }

    private async loadPayoutSettings() {
        try {
            this.payoutSettings = await this.http
                .get<PayoutSettings>(
                    `${environment.apiUrl}/v1/charity/settings/getPayoutSettings`,
                    {
                        headers: {
                            'X-Charity-Tenant': this.id,
                        },
                    },
                )
                .toPromise();
        } catch (error) {
            this.toast.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        }
    }

    private async savePayoutSettings(data: PayoutSettings) {
        return this.http
            .post(
                `${environment.apiUrl}/v1/charity/settings/updatePayoutSettings`,
                data,
                {
                    headers: {
                        'X-Charity-Tenant': this.id,
                    },
                },
            )
            .toPromise();
    }

    protected createForm() {
        this.form = this.fb.group({
            accountHolderName: this.fb.control<string>(
                this.payoutSettings?.accountHolderName ?? null,
                [Validators.required],
            ),
            accountNumber: this.fb.control<string>(
                this.payoutSettings?.accountNumber ?? null,
                [Validators.required],
            ),
            bsb: this.fb.control<string>(
                this.payoutSettings?.bsb ?? null,
                [Validators.required],
            ),
        });
    }

    public async handleSubmit() {
        if (!this.validateForm() || this.isSubmitting) {
            return;
        }

        try {
            this.isSubmitting = true;
            await this.savePayoutSettings(this.form.value);
            await this.handleSubmitSuccess();
        } catch (error) {
            this.toast.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        } finally {
            this.isSubmitting = false;
        }
    }

    private async handleSubmitSuccess() {
        const redirectUrl = this.route.snapshot.queryParams.redirectUrl;

        if (redirectUrl) {
            return this.router.navigateByUrl(redirectUrl);
        } else {
            this.toast.success('Your banking details have been updated.');
        }
    }

}

interface PayoutSettings {
    accountHolderName?: string;
    accountNumber?: string;
    bsb?: string;
}
