import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectHTMLElementRecord, loadConnectAndInitialize } from '@stripe/connect-js';
import { Environment } from '../../../../_services/environment.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { DashboardContextService } from '../../../contexts/dashboard-context.service';
import { RELIABLE_BLUE, RELIABLE_BLUE_10, OCCASIONAL_ORANGE } from '@little-phil/frontend/js/common/colours';
import { z } from 'zod';
import { WidgetLoaderComponent } from '../../../../_components/widget-loader/widget-loader.component';

@Component({
    selector: 'app-dashboard-settings-verification',
    standalone: true,
    imports: [CommonModule, WidgetLoaderComponent],
    templateUrl: './dashboard-settings-verification.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardSettingsVerificationComponent implements OnInit {

    public isLoading: boolean;

    constructor(
        private dashboardContext: DashboardContextService,
        private httpClient: HttpClient,
    ) {}

    ngOnInit() {
        this.isLoading = true;
        this.fetchClientSecret()
            .then((data) => {
                const stripeConnectInstance = loadConnectAndInitialize({
                    publishableKey: Environment.stripePublicKey,
                    fetchClientSecret: () => Promise.resolve(data.clientSecret),
                    appearance: {
                        overlays: 'dialog',
                        variables: {
                            borderRadius: '5px',
                            buttonBorderRadius: '100px',
                            buttonPrimaryColorBackground: OCCASIONAL_ORANGE,
                            buttonPrimaryColorText: '#ffffff',
                            buttonSecondaryColorBackground: RELIABLE_BLUE_10,
                            buttonSecondaryColorText: RELIABLE_BLUE,
                            colorPrimary: RELIABLE_BLUE,
                            colorText: RELIABLE_BLUE,
                            fontFamily: 'DM Sans',
                            fontSizeBase: '16px',
                            headingXlFontSize: '28px',
                            headingLgFontSize: '20px',
                            headingMdFontSize: '16px',
                            headingSmFontSize: '14px',
                            headingXsFontSize: '12px',
                            // spacingUnit: '10px', // NOTE: altering this breaks the phone number country selector
                        },
                    },
                    fonts: [
                        {
                            cssSrc: 'https://fonts.googleapis.com/css?family=DM+Sans',
                        },
                    ],
                });

                let component:
                    | ConnectHTMLElementRecord['account-management']
                    | ConnectHTMLElementRecord['account-onboarding']
                    | null = null;

                switch (data.type) {
                    case 'account-management':
                        const accountManagementComponent = stripeConnectInstance.create('account-management');
                        accountManagementComponent.setCollectionOptions({
                            fields: 'eventually_due',
                            futureRequirements: 'include',
                        });
                        component = accountManagementComponent;
                        break;
                    case 'account-onboarding':
                        const accountOnboardingComponent = stripeConnectInstance.create('account-onboarding');
                        accountOnboardingComponent.setCollectionOptions({
                            fields: 'eventually_due',
                            futureRequirements: 'include',
                        });
                        component = accountOnboardingComponent;
                        break;
                    default:
                        throw new Error('Failed to create onboarding element.');
                }

                const container = document.getElementById('container');
                container.appendChild(component);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    private async fetchClientSecret() {
        const charity = await this.dashboardContext.charityPromise;
        return this.httpClient
            .post(`${environment.apiUrl}/v1/charity/${charity.id}/verification-embed`, {})
            .toPromise()
            .then(data => verificationEmbedSchema.parse(data));
    }
}

const verificationEmbedSchema = z.object({
    clientSecret: z.string(),
    type: z.enum(['account-management', 'account-onboarding']),
});
