<ng-content></ng-content>

<div class="tab-bar">

    <div class="separator"></div>

    <p class="tab py-2 px-3"
       *ngFor="let item of items; let index = index"
       [class.active]="selectedItem === item"
       (click)="setSelectedItem(item)">
        {{item.title}}
    </p>

</div>

<div class="content" [class.overflow-y-scroll]="enabledOverflowScroll">
    <ng-template dynamic-component-container></ng-template>
</div>
