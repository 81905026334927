import { Component, ComponentRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { DynamicComponentContainerDirective } from '../../../lp-common/directives/dynamic-component-container.directive';

@Component({
    selector: 'app-top-navigator',
    templateUrl: './top-navigator.component.html',
    styleUrls: ['./top-navigator.component.scss'],
})
export class TopNavigatorComponent {

    @ViewChild(DynamicComponentContainerDirective, { static: true }) dynamicComponentContainer: DynamicComponentContainerDirective;
    @Input() enabledOverflowScroll = false;
    @Output() onItemSelected = new EventEmitter<TopNavigatorItem>();

    private _items: TopNavigatorItem[];
    private _selectedItem: TopNavigatorItem;

    constructor(
        private renderer: Renderer2,
    ) {}

    /**
     * Configures the side navigation instance
     * @param items
     * @param selectedItem
     */
    public setup(items: TopNavigatorItem[], selectedItem?: TopNavigatorItem) {
        this._items = items;

        if (items.length <= 0) {
            return;
        } else if (!selectedItem) {
            selectedItem = items[0];
        }

        this.renderComponent(selectedItem);
    }

    /**
     * Renders a new or already existing component
     * @private
     */
    public renderComponent(item: TopNavigatorItem) {

        if (this.selectedItem) {
            if (item === this.selectedItem) {
                // do nothing if selected component is already displayed
                return;
            } else {
                // hide the currently displayed component
                this.renderer.setStyle(this.selectedItem.componentRef.location.nativeElement, 'display', 'none');
            }
        }

        if (item.componentRef) {
            // update the display for the cached component
            this.renderer.setStyle(item.componentRef.location.nativeElement, 'display', 'block');
            this._selectedItem = item;
        } else {
            // create a new component, add it to the DOM and cache it
            item.componentRef = this.dynamicComponentContainer.viewContainerRef.createComponent(item.component);
            this._selectedItem = item;

            item.componentRef.instance.data = item.data;
        }

    }

    /**
     * Sets the selected tab
     * @param item
     */
    public setSelectedItem(item: TopNavigatorItem) {
        this.renderComponent(item);
        this.onItemSelected.emit(item);
    }

    get items() {
        return this._items;
    }

    get selectedItem() {
        return this._selectedItem;
    }

}

/**
 * Top nav item that is clickable and contains a component to render
 */
export class TopNavigatorItem {

    public componentRef?: ComponentRef<TopNavigatorItemComponent>;

    public readonly title: string;
    public readonly component: any;
    public readonly data: Record<string, any>;

    constructor(title: string, component: any, data?: Record<string, any>) {
        this.title = title;
        this.component = component;
        this.data = data;
    }
}

/**
 * Interface to providing data to a rendered component
 */
export interface TopNavigatorItemComponent {
    data?: Record<string, any>;
}
