/*
 * custom-validators.class.ts
 * Little Phil
 *
 * Created on 7/12/23
 * Copyright © 2018 Little Phil. All rights reserved.
 */

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isUrl } from '@little-phil/js/lib/utils/validators';

/**
 * Inspiration taken from the Angular Validators class
 */
export class CustomValidators {

    static url(options?: {
        allowPath?: boolean,
        allowQuery?: boolean,
        requireProtocol?: boolean,
    }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }

            const isValid = isUrl(value, options);

            return !isValid ? { pattern: true } : null;
        };
    }

}
