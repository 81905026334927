import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TopNavigatorComponent } from './components/top-navigator/top-navigator.component';
import { SideNavigatorComponent } from './components/side-navigator/side-navigator.component';
import { LpCommonModule } from '../lp-common/lp-common.module';

const components = [
    SideNavigatorComponent,
    TopNavigatorComponent,
];

@NgModule({
    declarations: [...components],
    imports: [
        LpCommonModule,
        RouterModule,
    ],
    exports: [...components],
})
export class LpNavigationModule {}
