<div class="d-flex flex-column flex-lg-row">

    <ul class="navigator nav nav-pills flex-column mb-7 pe-lg-6">
        <li class="nav-item" *ngFor="let item of items; let index = index">

            <div *ngIf="item.type === SIDE_NAVIGATOR_ITEM_TYPE.SELECTABLE_ITEM && item | as: SideNavigatorSelectableItem as item">
                <a class="nav-link py-2 px-3 mb-1"
                   [class.active]="selectedItem === item"
                   (click)="renderComponent(item)">
                    {{item.title}}
                </a>
            </div>

            <div *ngIf="item.type === SIDE_NAVIGATOR_ITEM_TYPE.LINK && item | as: SideNavigatorLink as item">
                <a class="nav-link py-2 px-3 mb-1"
                   [routerLink]="item.link">
                    {{item.title}}
                </a>
            </div>

            <div *ngIf="item.type === SIDE_NAVIGATOR_ITEM_TYPE.SEPARATOR && item | as: SideNavigatorSeparator as item"
                 class="mb-2 ps-3"
                 [class.mt-3]="index === 0"
                 [class.mt-6]="index !== 0">
                <p class="font-size-xs title" *ngIf="item.title">{{item.title}}</p>
                <hr class="m-0">
            </div>

        </li>
    </ul>

    <div class="flex-grow-1 mb-7">
        <ng-template dynamic-component-container></ng-template>
    </div>

</div>
