import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CharityCardComponent } from '../../../../_components/charity-card/charity-card.component';
import { DashboardFormControlComponent } from '../../../../_components/forms/dashboard-form-control/dashboard-form-control.component';
import { DashboardFormInputComponent } from '../../../../_components/forms/dashboard-form-input/dashboard-form-input.component';
import { SvgIconComponent } from '../../../../_components/svg-icon/svg-icon.component';
import { FormComponent } from '../../../../_classes/form-component.class';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoggerService } from '../../../../_services/logger.service';
import { AuthService } from '../../../../_services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ARROW_RIGHT_SVG } from 'src/app/_utils/icons-brand.util';
import { ApiService } from '../../../../_services/api.service';
import { Charity } from '../../../../_models/charity.model';
import { extractErrorMessageFromError } from '../../../../_utils/errors.util';
import { SentryService } from '../../../../_services/sentry.service';
import { WidgetLoaderComponent } from '../../../../_components/widget-loader/widget-loader.component';
import { CustomValidators } from '../../../../_classes/custom-validators.class';

@Component({
    selector: 'app-dashboard-settings-organisation',
    standalone: true,
    imports: [
        CommonModule,
        CharityCardComponent,
        DashboardFormControlComponent,
        DashboardFormInputComponent,
        SvgIconComponent,
        ReactiveFormsModule,
        WidgetLoaderComponent,
    ],
    templateUrl: './dashboard-settings-organisation.component.html',
    styles: [
        `
            :host {
                display: block;
            }
        `,
    ],
})
export class DashboardSettingsOrganisationComponent extends FormComponent implements OnInit {

    private id: string;
    private charity: Charity;

    public isLoading = false;
    public isSubmitting = false;

    public form: FormGroup<{
        businessName: FormControl<string>,
        businessTaxId: FormControl<string>,
        businessUrl: FormControl<string>,
        contactEmail: FormControl<string>,
        contactPhoneNumber: FormControl<string>,
    }>;

    protected readonly ARROW_RIGHT_SVG = ARROW_RIGHT_SVG;

    constructor(
        protected logger: LoggerService,
        private apiService: ApiService,
        private authService: AuthService,
        private fb: FormBuilder,
        private toast: ToastrService,
        private sentry: SentryService,
    ) {
        super(logger);
    }

    ngOnInit() {
        const user = this.authService.getUser();

        this.id = user.managedCharity;
        if (!this.id) {
            return;
        }

        this.loadCharity()
            .then(() => this.createForm());
    }

    private async loadCharity() {
        try {
            this.charity = await this.apiService.charity.retrieve(this.id);
        } catch ({ error }) {
            this.toast.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        }
    }

    private saveCharity(data: Partial<{
        businessName: string,
        businessUrl: string,
        contactEmail: string,
        contactPhoneNumber: string,
    }>) {
        return this.apiService.charity.update(this.id, {
            businessName: data.businessName,
            businessUrl: data.businessUrl || null,
            contactDetails: {
                email: data.contactEmail || null,
                phoneNumber: data.contactPhoneNumber || null,
            },
        });
    }

    /**
     * Sets up the forms initial state
     */
    protected createForm() {
        this.form = this.fb.group({
            // Details
            businessName: this.fb.control<string>(
                this.charity?.displayName || null,
                [Validators.required],
            ),
            businessTaxId: this.fb.control<string>(
                this.charity?.businessTaxId || null,
            ),
            businessUrl: this.fb.control<string>(
                this.charity?.businessUrl || null,
                [
                    Validators.required,
                    CustomValidators.url(),
                ],
            ),

            // Contact information
            contactEmail: this.fb.control<string>(
                this.charity?.contactDetails?.email || null,
            ),
            contactPhoneNumber: this.fb.control<string>(
                this.charity?.contactDetails?.phoneNumber || null,
            ),
        });

        this.form.controls.businessTaxId.disable();
    }

    public async handleSubmit() {
        if (!this.validateForm()) {
            return;
        }

        try {
            this.isSubmitting = true;
            await this.saveCharity(this.form.value);
            this.toast.success('Your organisation details have been updated.');
        } catch (error) {
            this.toast.error(extractErrorMessageFromError(error));
            this.sentry.trackIssue(error);
        } finally {
            this.isSubmitting = false;
        }
    }

}
