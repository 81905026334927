import { Component, ComponentRef, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicComponentContainerDirective } from '../../../lp-common/directives/dynamic-component-container.directive';

@Component({
    selector: 'app-side-navigator',
    templateUrl: './side-navigator.component.html',
    styleUrls: ['./side-navigator.component.scss'],
})
export class SideNavigatorComponent {

    @ViewChild(DynamicComponentContainerDirective, { static: true }) dynamicComponentContainer: DynamicComponentContainerDirective;

    private _items: SideNavigatorItem[];
    private _selectedItem: SideNavigatorSelectableItem;

    public readonly SIDE_NAVIGATOR_ITEM_TYPE = SIDE_NAVIGATOR_ITEM_TYPE;
    public readonly SideNavigatorLink = SideNavigatorLink;
    public readonly SideNavigatorSelectableItem = SideNavigatorSelectableItem;
    public readonly SideNavigatorSeparator = SideNavigatorSeparator;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    /**
     * Configures the side navigation instance
     * @param items
     * @param selectedItem
     */
    public setup(items: SideNavigatorItem[], selectedItem?: SideNavigatorSelectableItem) {
        this._items = items;

        if (items.length <= 0) {
            return;
        } else if (!selectedItem) {

            // find the selected item if there was a query param provided
            const sectionName = this.route.snapshot?.queryParams?.section;
            selectedItem = items
                .filter((item) => item.type === SIDE_NAVIGATOR_ITEM_TYPE.SELECTABLE_ITEM)
                .find((item: SideNavigatorSelectableItem) => item.name === sectionName) as SideNavigatorSelectableItem;

            // else the first will be selected
            if (!selectedItem) {
                const firstSelectableItem = items.find(item => item.type === SIDE_NAVIGATOR_ITEM_TYPE.SELECTABLE_ITEM);
                selectedItem = firstSelectableItem as SideNavigatorSelectableItem;
            }

        }

        this.renderComponent(selectedItem);
    }

    /**
     * Renders a new or already existing component
     * @private
     */
    public renderComponent(item: SideNavigatorSelectableItem) {

        if (this.selectedItem) {
            if (item === this.selectedItem) {
                // do nothing if selected component is already displayed
                return;
            } else {
                // hide the currently displayed component
                this.renderer.setStyle(this.selectedItem.componentRef.location.nativeElement, 'display', 'none');
            }
        }

        if (item.componentRef) {
            // update the display for the cached component
            this.renderer.setStyle(item.componentRef.location.nativeElement, 'display', 'block');
            this._selectedItem = item;
        } else {
            // create a new component, add it to the DOM and cache it
            item.componentRef = this.dynamicComponentContainer.viewContainerRef.createComponent(item.component);
            this._selectedItem = item;
        }

        // Update query param to keep state on refresh
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { section: item.name },
            queryParamsHandling: 'merge',
            replaceUrl: true,
        }).catch(console.error);


    }

    get items() {
        return this._items;
    }

    get selectedItem() {
        return this._selectedItem;
    }

}

/**
 * Enum of different types of side nav items
 */
enum SIDE_NAVIGATOR_ITEM_TYPE {
    LINK = 'link',
    SELECTABLE_ITEM = 'selectableItem',
    SEPARATOR = 'separator',
}

/**
 * Base class for all side nav items to extend
 */
class SideNavigatorItem {
    readonly type: SIDE_NAVIGATOR_ITEM_TYPE;
}

/**
 * Side nav item that is clickable and contains a component to render
 */
export class SideNavigatorSelectableItem extends SideNavigatorItem {

    public componentRef?: ComponentRef<any>;

    public readonly type = SIDE_NAVIGATOR_ITEM_TYPE.SELECTABLE_ITEM;
    public readonly title: string; // title in the side menu
    public readonly component: any; // component to render
    public readonly name: string; // name of the item, this is used as a query param

    constructor(
        title: string,
        name: string,
        component: any,
    ) {
        super();
        this.title = title;
        this.name = name;
        this.component = component;
    }
}

/**
 * Side nav item separator
 */
export class SideNavigatorSeparator extends SideNavigatorItem {
    readonly type = SIDE_NAVIGATOR_ITEM_TYPE.SEPARATOR;
    public readonly title?: string;

    constructor(title?: string) {
        super();
        this.title = title;
    }
}

export class SideNavigatorLink extends SideNavigatorItem {
    readonly type = SIDE_NAVIGATOR_ITEM_TYPE.LINK;
    public readonly title: string;
    public readonly link: string;

    constructor(title: string, link: string) {
        super();
        this.title = title;
        this.link = link;
    }
}
