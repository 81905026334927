"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isUrl = exports.isStripeSubscriptionId = exports.isStripePriceId = exports.isStripeCustomerId = exports.isPassword = exports.isPhoneNumber = exports.isEmail = void 0;
/*
 * validators.ts
 * Little Phil
 *
 * Created on 5/8/20
 * Copyright © 2018 Little Phil. All rights reserved.
 */
const regex_1 = require("./regex");
const domainPartRegex = /^[0-9a-zA-Z-]{2,128}$/;
/**
 * Tests the given email address against the emailPattern
 * @param email
 */
const isEmail = (email) => regex_1.emailPattern.test(email);
exports.isEmail = isEmail;
/**
 * Tests the given phone number against the phoneNumberPattern
 * @param number
 */
const isPhoneNumber = (number) => regex_1.phoneNumberPattern.test(number);
exports.isPhoneNumber = isPhoneNumber;
/**
 * Tests the given password against the passwordPattern
 * @param password
 */
const isPassword = (password) => regex_1.passwordPattern.test(password);
exports.isPassword = isPassword;
/**
 * Tests that the given customer ID is a Stripe customer ID
 * @param priceId
 */
const isStripeCustomerId = (priceId) => priceId.startsWith('cus_');
exports.isStripeCustomerId = isStripeCustomerId;
/**
 * Tests that the given price ID is a Stripe price ID
 * @param priceId
 */
const isStripePriceId = (priceId) => priceId.startsWith('price_');
exports.isStripePriceId = isStripePriceId;
/**
 * Tests that the given subscription ID is a Stripe subscription ID
 * @param subscriptionId
 */
const isStripeSubscriptionId = (subscriptionId) => subscriptionId.startsWith('sub_');
exports.isStripeSubscriptionId = isStripeSubscriptionId;
/**
 * Tests the given website is a valid URL
 * @param website
 * @param options
 */
const isUrl = (website, options) => {
    const hasProtocol = website.includes('://');
    if (!hasProtocol) {
        if (options === null || options === void 0 ? void 0 : options.requireProtocol) {
            return false;
        }
        else {
            website = `https://${website}`; // prepend protocol, however this has no effect on the raw URL
        }
    }
    try {
        const result = new URL(website);
        if (result.protocol !== 'http:' && result.protocol !== 'https:') {
            return false;
        }
        if (result.pathname && result.pathname !== '/' && !(options === null || options === void 0 ? void 0 : options.allowPath)) {
            return false;
        }
        if (result.search && !(options === null || options === void 0 ? void 0 : options.allowQuery)) {
            return false;
        }
        return true;
    }
    catch (e) {
        return false;
    }
};
exports.isUrl = isUrl;
